<template>
	<div class="bigBox">
		<h2>告警日志</h2>
		<div class="">
			<div class="iptsBox margin-top20">
				<el-form ref="form" :model="form" label-width="80px">
					<div class="flex-sb">
						<div class="row-me">
							<el-date-picker v-model="form.value1" type="daterange" align="center" range-separator="至"
								start-placeholder="开始日期" end-placeholder="结束日期" format="YYYY-MM-DD"
								value-format="YYYY-MM-DD" @change="onChange">
							</el-date-picker>
							<el-input v-model="form.keyword" prefix-icon="Search" placeholder="IEME编码/设备编码/设备名称"
								class="namePut"></el-input>
							<el-select v-model="form.company_id" placeholder="所属公司" class="electIpt" @focus="comSelect">
								<el-option v-for="(item,index) in comList" :key="index" :value="item.id"
									:label="item.name">
								</el-option>
							</el-select>
							<el-button type="primary" icon="Search" class="searchs" @click="search">搜索</el-button>
							<el-button class="agains" @click="resetting">重置
							</el-button>
						</div>
					</div>
				</el-form>
			</div>
			<div class="tabsBox  margin-top20">
				<el-table ref="singleTable" :data="logTabList" border header-cell-class-name="table-header-style"
					cell-class-name="table-cell-style">
					<el-table-column type="index" label="序号" width="70" align="center">
					</el-table-column>
					<el-table-column prop="device_name" label="设备名称" align="center">
					</el-table-column>
					<el-table-column prop="ieme_encoded" label="IEMI编码" align="center">
					</el-table-column>
					<el-table-column prop="device_encoded" label="设备编码" align="center">
					</el-table-column>
					<el-table-column prop="company_name" label="所属公司" align="center">
					</el-table-column>
					<el-table-column prop="log_details" label="日志详情" align="center">
					</el-table-column>
					<el-table-column prop="create_time" label="报警时间" align="center">
					</el-table-column>
				</el-table>
				<div class="row-me row-center flex-end1 margin-top10" v-if="logTabList.length>0">
					<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
						:current-page.sync="pageNo" :total="total">
					</el-pagination>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		deviceAlarmLog,
		sysCompanyOptionList
	} from "@/api/deviceManagement/deviceManagement.js"
	export default {
		data() {
			return {

				form: {
					name: "",
					keyword: "",
					value1: [],
					company_id: ""
				},
				logTabList: [],
				comList: [],
				pageNo: 1,
				total: 0,
			}
		},
		mounted() {
			this.getLog()
		},
		methods: {
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.getLog()
			},
			// 报警日志表格
			async getLog() {
				const res = await deviceAlarmLog({
					start_time: this.form.value1[0],
					end_time: this.form.value1[1],
					keyword: this.form.keyword,
					company_id: this.form.company_id,
					page: this.pageNo,
				})
				this.logTabList = res.data.listInfo
				this.total = res.data.allListNumber
			},
			// 所属公司下拉框
			async comSelect() {
				const res = await sysCompanyOptionList()
				this.comList = res.data
			},
			// 搜索
			search() {
				this.getLog()
			},
			// onChange(){
			// 	console.log(this.form.value1,"99")
			// },
			// 重置
			resetting() {
				this.pageNo = 1;
				this.form.keyword = ''
				this.form.company_id = ''
				this.form.value1 = []
				this.getLog()
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
	}

	.youzhi {
		width: 120px;
		font-size: 18px;
		float: right;
	}

	.agains {
		margin-left: 10px;
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	.searchs {
		margin-left: 10px;
		font-size: 16px;
	}

	.namePut {
		width: 360px;
		height: 40px;
		margin-left: 10px;
	}

	.electIpt {
		width: 200px;
		height: 36px;
		margin-left: 10px;

		/deep/.el-input {
			height: 40px;
		}
	}

	.fenye {
		float: right;
		margin-top: 40px;
	}

	::v-deep .el-button--primary {
		width: 100px;
		height: 40px;
	}
</style>